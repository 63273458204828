<template>
  <tab-simple
    v-model:value="currentTab"
    :items="Object.values(tabs)"
  />
</template>

<script setup lang="ts">
import { PayType } from '@skeleton/consts/method';

const currentTab = defineModel('modelValue', {
  type: String,
  default: 'deposit',
});

const { getContent } = useProjectMethods();
const { popupsData, defaultLocalePopupsData } = storeToRefs(useGlobalStore());

const tabs = computed(() => {
  return {
    games: {
      id: PayType.DEPOSIT,
      name: getContent(popupsData.value, defaultLocalePopupsData.value, 'wallet.deposit.title'),
    },
    sports: {
      id: PayType.WITHDRAW,
      name: getContent(popupsData.value, defaultLocalePopupsData.value,'wallet.withdraw.title'),
    },
  }
})
</script>