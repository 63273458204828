import { useApiAuthInstance } from '@skeleton/core/assets/apiAuthInstance';

import type { CurrencyRate } from '~/types';

export const useRates = () => {
  const rates = ref<CurrencyRate[]>([]);

  const fetchRates = async () => {
    try {
      const { data } = await useApiAuthInstance('/api/settings/currencies/rates');
      rates.value = data;
    } catch (err) {
      console.error(err);
    }
  };

  const convertCurrency = (amount: number | string, toCode: string, fromCode: string = 'USD'): number => {
    const fromCurrency = rates.value.find(rate => rate.code === fromCode);
    const toCurrency = rates.value.find(rate => rate.code === toCode);

    if (!fromCurrency || !toCurrency) {
      console.error(`Currency ${fromCode} or ${toCode} not found`);
      return 0;
    }

    const fromToUSD = fromCurrency.rate.rate / fromCurrency.subunitToUnit;

    const toToUSD = toCurrency.rate.rate / toCurrency.subunitToUnit;

    const convertedAmount = (Number(amount) * fromToUSD) / toToUSD;
    return Number(convertedAmount.toFixed(2));
  };

  const convertFromUSD = (amount: number | string, toCode: string): number => {
    const toCurrency = rates.value.find(rate => rate.code === toCode);

    if (!toCurrency) {
      console.error(`Currency ${toCode} not found`);
      return 0;
    }

    const toRate = toCurrency.rate.rate / toCurrency.subunitToUnit;
    const convertedAmount = Number(amount) * toRate;
    return Number(convertedAmount.toFixed(2));
  };

  return { convertFromUSD, convertCurrency, fetchRates };
};
