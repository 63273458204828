import { TonConnectUI } from '@tonconnect/ui';

export class TonConnector {
  private static instance: TonConnector;
  private readonly tonConnectUI: TonConnectUI;

  private constructor(manifestUrl: string) {
    this.tonConnectUI = new TonConnectUI({
      manifestUrl: manifestUrl,
    });
  }

  public static getInstance(manifestUrl: string) {
    if (!TonConnector.instance) {
      TonConnector.instance = new TonConnector(manifestUrl);
    }
    return TonConnector.instance;
  }

  public getTonConnectUI(): TonConnectUI {
    return this.tonConnectUI;
  }
}
