import { Address, fromNano, toNano, JettonMaster, JettonWallet, TonClient, beginCell, Cell } from '@ton/ton';
import { CHAIN, THEME, toUserFriendlyAddress } from '@tonconnect/ui';
import type { Wallet, WalletsModalState } from '@tonconnect/ui';
import { getHttpEndpoint } from '@orbs-network/ton-access';

import { TonConnector } from '@skeleton/classes/TonConnector';

const JETTON_USDT_MASTER_ADDRESS = 'EQCxE6mUtQJKFnGfaROTKOt1lZbDiiX1kCixRv7Nw2Id_sDs';
const DECIMALS = 6;
const DEFAULT_AMOUNT = '0.00';

export const useTonConnect = () => {
  const {
    public: { apiBaseUrl },
  } = useRuntimeConfig();

  const client = ref<TonClient>();

  const connector = TonConnector.getInstance(`${apiBaseUrl}/tonconnect-manifest.json`);
  const tonConnectUI = connector.getTonConnectUI();

  let unsubscribeStatus: () => void;
  let unsubscribeStateModal: () => void;

  const { colorPreference } = useColorModeLogic();

  const restored = ref(false);
  const isProcessing = ref(false);
  const isInitialisation = ref(false);
  const isWalletConnected = ref(tonConnectUI.connected);
  const modalState = ref<WalletsModalState>();
  const wallet = ref<Wallet | null>(null);
  const tonCoinBalance = ref(DEFAULT_AMOUNT);
  const usdtBalance = ref(DEFAULT_AMOUNT);

  onBeforeMount(async () => {
    isInitialisation.value = true;
    const endpoint = await getHttpEndpoint();
    client.value = new TonClient({ endpoint });
    isInitialisation.value = false;
  });

  onMounted(() => {
    if (tonConnectUI) {
      wallet.value = tonConnectUI.wallet;

      let theme: THEME | 'SYSTEM';

      switch (colorPreference.value) {
        case 'light':
          theme = THEME.LIGHT;
          break;
        case 'dark':
          theme = THEME.DARK;
          break;
        default:
          theme = 'SYSTEM';
      }

      tonConnectUI.uiOptions = {
        uiPreferences: {
          theme,
        },
      };
    }
  });

  onUnmounted(() => {
    unsubscribeStatus?.();
    unsubscribeStateModal?.();
  });

  const truncateToDecimals = (numStr: string, decimalPlaces: number): string => {
    const decimalIndex = numStr.indexOf('.');

    if (decimalIndex === -1) {
      return numStr;
    }
    return numStr.slice(0, decimalIndex + decimalPlaces + 1);
  };

  const walletAddress = computed(() => {
    const addressStr = wallet.value?.account.address;
    if (addressStr) {
      const userFriendlyAddress = toUserFriendlyAddress(addressStr, wallet.value?.account.chain === CHAIN.TESTNET);

      return `${userFriendlyAddress.slice(0, 6)}...${userFriendlyAddress.slice(userFriendlyAddress.length - 4)}`;
    }
    return '';
  });

  const getJettonWalletAddress = async () => {
    if (!wallet.value?.account.address || !client.value) {
      return undefined;
    }

    try {
      const jettonMaster = client.value.open(JettonMaster.create(Address.parse(JETTON_USDT_MASTER_ADDRESS)));

      return await jettonMaster.getWalletAddress(Address.parse(wallet.value?.account.address));
    } catch (e) {
      console.log(e);
      return undefined;
    }
  };

  const updateTonCoinBalance = async () => {
    if (wallet.value?.account?.address && client.value) {
      try {
        const res = await client.value.getBalance(Address.parse(wallet.value.account.address));
        tonCoinBalance.value = truncateToDecimals(fromNano(res), 2);
      } catch (e) {
        console.log(e);
      }
    } else {
      tonCoinBalance.value = DEFAULT_AMOUNT;
    }
  };

  const updateUsdtBalance = async () => {
    if (!client.value) return;

    const jettonWalletAddress = await getJettonWalletAddress();

    if (!jettonWalletAddress) return;

    const jettonWalletContract = client.value.open(JettonWallet.create(jettonWalletAddress));

    if (!jettonWalletContract) return;

    try {
      const res = await jettonWalletContract.getBalance();
      usdtBalance.value = truncateToDecimals((Number(res) / 10 ** DECIMALS).toString(), 2);
    } catch (e) {
      console.log(e);
    }
  };

  const disconnect = async () => {
    await tonConnectUI.disconnect();
    isWalletConnected.value = tonConnectUI.connected;
    tonCoinBalance.value = DEFAULT_AMOUNT;
    usdtBalance.value = DEFAULT_AMOUNT;
  };

  const createPayloadCell = (message: string): Cell => {
    return beginCell().storeUint(0, 32).storeStringTail(message).endCell();
  };

  const sendTonCoins = async (amount: string, addressTo: string, destinationTag: string) => {
    if (!amount || !addressTo || !destinationTag) {
      return false;
    }

    const payloadCell = createPayloadCell(destinationTag);

    const transaction = {
      validUntil: Math.floor(Date.now() / 1000) + 60,
      messages: [
        {
          address: addressTo,
          amount: toNano(amount).toString(),
          payload: payloadCell.toBoc().toString('base64'),
        },
      ],
    };

    try {
      isProcessing.value = true;
      await tonConnectUI.sendTransaction(transaction);
      await updateTonCoinBalance();
      return true;
    } catch (e) {
      console.log('Error', e);
      return false;
    } finally {
      isProcessing.value = false;
    }
  };

  const sendUSDTjettons = async (amount: string, addressTo: string, destinationTag: string) => {
    if (!amount || !addressTo || !destinationTag) {
      return false;
    }

    const forwardPayload = createPayloadCell(destinationTag);

    const body = beginCell()
      .storeUint(0xf8a7ea5, 32)
      .storeUint(BigInt(Date.now()), 64)
      .storeCoins(BigInt(Math.round(Number(amount) * 10 ** DECIMALS)))
      .storeAddress(Address.parse(addressTo))
      .storeAddress(Address.parse(wallet.value?.account.address!))
      .storeBit(0)
      .storeCoins(1)
      .storeBit(1)
      .storeRef(forwardPayload)
      .endCell();

    const jettonWalletAddress = await getJettonWalletAddress();

    if (!jettonWalletAddress) return false;

    const transaction = {
      validUntil: Math.floor(Date.now() / 1000) + 60,
      messages: [
        {
          address: jettonWalletAddress.toString(),
          amount: toNano('0.05').toString(),
          payload: body.toBoc().toString('base64'),
        },
      ],
    };

    try {
      isProcessing.value = true;
      await tonConnectUI.sendTransaction(transaction);
      await updateUsdtBalance();
      return true;
    } catch (e) {
      console.log('Error', e);
      return false;
    } finally {
      isProcessing.value = false;
    }
  };

  watchEffect(async () => {
    if (tonConnectUI) {
      tonConnectUI.connectionRestored.then(async () => {
        restored.value = true;
        isWalletConnected.value = tonConnectUI.connected;
      });
    }
  });

  watchEffect(() => {
    if (tonConnectUI) {
      unsubscribeStatus = tonConnectUI.onStatusChange(connectedWallet => {
        wallet.value = connectedWallet;
        if (connectedWallet) {
          isWalletConnected.value = true;
        }
      });
    }
  });

  watchEffect(async () => {
    if (restored.value && wallet.value?.account?.address) {
      await updateTonCoinBalance();
      await updateUsdtBalance();
    }
  });

  watchEffect(() => {
    if (tonConnectUI) {
      modalState.value = tonConnectUI.modal.state;
      unsubscribeStateModal = tonConnectUI.onModalStateChange((value: WalletsModalState) => {
        modalState.value = value;
      });
    }
  });

  return {
    restored,
    openModal: () => tonConnectUI?.modal.open(),
    closeModal: () => tonConnectUI?.modal.close(),
    disconnect,
    isWalletConnected,
    modalState,
    walletAddress,
    tonCoinBalance,
    usdtBalance,
    sendTonCoins,
    sendUSDTjettons,
    isProcessing,
    isInitialisation,
    truncateToDecimals,
  };
};
