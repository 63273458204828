<template>
  <div class="deposit-preset-amount-buttons">
    <div
      class="deposit-preset-amount-buttons__item"
      v-for="preset in presets"
      :key="preset.name"
      @click="onSelectPreset(preset.value)"
    >
      {{ preset.name }}
    </div>
  </div>
</template>

<script setup lang="ts">
  interface Preset {
    name: string;
    value: number;
  }

  const props = withDefaults(
    defineProps<{
      max: number;
    }>(),
    {
      max: 0,
    }
  );

  const emits = defineEmits<{
    (event: 'onSelectPreset', value: number): void;
  }>();

  const { getContent } = useProjectMethods();
  const { popupsData, defaultLocalePopupsData } = storeToRefs(useGlobalStore());

  const presets = computed<Preset[]>(() => {
    return [
      { name: '$5', value: 5 },
      { name: '$10', value: 10 },
      { name: '$20', value: 20 },
      { name: '$100', value: 100 },
      {
        name: getContent(popupsData.value, defaultLocalePopupsData.value, 'wallet.tonConnect.max'),
        value: props.max,
      },
    ];
  });

  const onSelectPreset = (value: number): void => {
    emits('onSelectPreset', value);
  };
</script>

<style lang="scss" src="~/assets/styles/components/form/deposit-preset-amount-buttons.scss" />
