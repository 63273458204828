<template>
  <div class="empty-wallet">
    <div class="empty-wallet__title">{{ title }}</div>

    <div class="empty-wallet__subtitle">{{ subtitle }}</div>

    <div class="empty-wallet__icon">
      <atomic-icon-colored :id="iconId" />
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { useColorModeLogic } from '@skeleton/composables/useColorModeLogic';

 withDefaults(
    defineProps<{
      title: string;
      subtitle: string;
    }>(),
    {
      title: '',
      subtitle: '',
    }
  );

  const { getColorMode } = useColorModeLogic();

  const iconId = computed(() => getColorMode() === 'dark' ? 'empty-wallet-dark' : 'empty-wallet');
</script>

<style lang="scss" src="~/assets/styles/components/atomic/empty-wallet.scss" />
