<template>
  <div class="wallet-telegram-app-withdraw">
    <skeletor v-if="isLoadingSupportedCurrencies" class="wallet-telegram-app-withdraw__skeletor" as="div" />

    <div v-else>
      <form-input-display
        :label="getContent(fieldsSettings, defaultLocaleFieldsSettings, 'fieldsControls.balance.label')"
        :value="balanceObject?.value || ''"
        :img-url="balanceObject?.mask"
        :is-mobile="isMobile"
        :is-open="isOpenBalanceSelector"
        @click-selector="onSelectCurrency"
      >
        <template v-if="!isMobile" #header>
          <div class="header">
            <form-input-search
              v-model:value="searchCurrency"
              :placeholder="getContent(fieldsSettings, defaultLocaleFieldsSettings, `fieldsControls.search.label`)"
            />
          </div>
        </template>

        <list-simple
          v-model:value="currentCurrencyId"
          :searchValue="searchCurrency"
          :list="filteredCurrencyList"
          @selected="selectCurrency"
        />
      </form-input-display>

      <div class="wallet-telegram-app-withdraw__info">
        <div>{{ getContent(popupsData, defaultLocalePopupsData, 'wallet.withdraw.info') }}</div>

        <div>
          {{ getContent(popupsData, defaultLocalePopupsData, 'wallet.withdraw.bonusValue') }}:
          <span>{{ `${bonusBalance?.bonusBalance} ${bonusBalance?.currency}` }}</span>
        </div>
      </div>
    </div>

    <skeletor
      v-if="isLoadingDataMethods || isLoadingSupportedCurrencies"
      class="wallet-telegram-app-withdraw__skeletor--sum"
      as="div"
    />

    <form-input-number
      v-else
      v-model:value="amountValue"
      :label="getContent(popupsData, defaultLocalePopupsData, 'wallet.withdraw.sumLabel')"
      name="withdrawSum"
      :min="formatAmountMin.amount"
      :max="formatAmountMax.amount"
      :currency="formatAmountMin.currency"
      :hint="fieldHint"
    />

    <skeletor
      v-if="isLoadingDataMethods || isLoadingSupportedCurrencies"
      class="wallet-telegram-app-withdraw__skeletor--address"
      as="div"
    />

    <div v-else>
      <form-input-text
        v-model:value="withdrawFormData.cryptoAddress"
        :name="currentFiled?.key"
        :label="getContent(fieldsSettings, defaultLocaleFieldsSettings, 'fieldsControls.wallet_id.label')"
        :placeholder="getContent(fieldsSettings, defaultLocaleFieldsSettings, 'fieldsControls.wallet_id.placeholder')"
        :hint="setError(currentFiled?.key)"
        type="text"
        @blur="v$.cryptoAddress?.$touch()"
        @input="v$.cryptoAddress?.$touch()"
      />

      <div class="wallet-telegram-app-withdraw__details">
        <div class="wallet-telegram-app-withdraw__details-row">
          <div class="wallet-telegram-app-withdraw__details-label">
            {{ getContent(popupsData, defaultLocalePopupsData, 'wallet.withdraw.withdrawalAmount') }}
          </div>

          <div class="wallet-telegram-app-withdraw__details-divider" />

          <div class="wallet-telegram-app-withdraw__details-value">
            {{ withdrawalAmount.amount }} {{ withdrawalAmount.currency }}
          </div>
        </div>

        <div class="wallet-telegram-app-withdraw__details-row">
          <div class="wallet-telegram-app-withdraw__details-label">
            {{ getContent(popupsData, defaultLocalePopupsData, 'wallet.withdraw.estimatedFee') }}
          </div>

          <div class="wallet-telegram-app-withdraw__details-divider" />

          <div class="wallet-telegram-app-withdraw__details-value">
            ~{{ estimatedFee.amount }} {{ estimatedFee.currency }}
          </div>
        </div>

        <div class="wallet-telegram-app-withdraw__details-row">
          <div class="wallet-telegram-app-withdraw__details-label">
            {{ getContent(popupsData, defaultLocalePopupsData, 'wallet.withdraw.youWillGet') }}
          </div>

          <div class="wallet-telegram-app-withdraw__details-divider" />

          <div class="wallet-telegram-app-withdraw__details-value">
            ~{{ youWillGet.amount.toFixed(2) }} {{ youWillGet.currency }}
          </div>
        </div>
      </div>
    </div>

    <skeletor
      v-if="isLoadingDataMethods || isLoadingSupportedCurrencies"
      class="wallet-telegram-app-withdraw__skeletor--btn"
      as="div"
    />

    <button-base
      v-else
      type="secondary"
      size="md"
      :isDisabled="buttonDisabled"
      :is-processing="isSending"
      @click="getWithdraw"
    >
      {{ getContent(popupsData, defaultLocalePopupsData, 'wallet.withdraw.withdrawButton') }}
      {{ withdrawalAmount.amount }}
      {{ withdrawalAmount.currency }}
    </button-base>

    <layout-bottom-sheet ref="balanceSelector">
      <template #header>
        <span class="title">
          {{ getContent(layoutData, defaultLocaleLayoutData, 'header.balance.tabs.coins') }}
        </span>
      </template>

      <list-simple
        v-model:value="currentCurrencyId"
        :searchValue="searchCurrency"
        :list="filteredCurrencyList"
        @selected="selectCurrency"
      />

      <template #footer>
        <button-base type="gray" size="sm" @click="toggleBottomSheetBalanceSelector('close')">
          {{ getContent(layoutData, defaultLocaleLayoutData, 'buttons.close') }}
        </button-base>
      </template>
    </layout-bottom-sheet>
  </div>
</template>

<script setup lang="ts">
  import { Skeletor } from 'vue-skeletor';

  import { PayMethod } from '@skeleton/consts/method';
  import { ModalName } from '@skeleton/consts/modals';

  import type { IPaymentField, IPaymentGroup, IPaymentMethod } from '@skeleton/core/types';

  interface SelectOption {
    value: string;
    minAmount: number;
    maxAmount: number;
    code: string;
    regex: RegExp;
  }

  const deviceStore = useDeviceStore();
  const { isMobile } = storeToRefs(deviceStore);
  const { showAlert } = useLayoutStore();
  const { closeModalSync } = useModalStore();
  const { formatBalance, getContent, getFormRules } = useProjectMethods();
  const transactionStore = useTransactionStore();
  const { selectedCurrency, withdrawMethods, isLoadingDataMethods } = storeToRefs(transactionStore);
  const {
    popupsData,
    defaultLocalePopupsData,
    fieldsSettings,
    defaultLocaleFieldsSettings,
    layoutData,
    defaultLocaleLayoutData,
    alertsData,
    defaultLocaleAlertsData,
  } = storeToRefs(useGlobalStore());
  const { currencyList, balanceObject, currentCurrencyId, isLoadingSupportedCurrencies, supportedTransactionAccounts } =
    useTransactionOperations({ method: PayMethod.WITHDRAW_WALLET_DIRECT_METHOD });

  const isOpenBalanceSelector = ref(false);
  const isSending = ref(false);

  const searchCurrency = ref('');
  const balanceSelector = ref();

  const filteredCurrencyList = computed(() => {
    return currencyList.value.filter(item => item.name === 'TON' || item.name === 'USDT');
  });

  const findPaymentMethodInGroups = (groups: IPaymentGroup[], method: string): IPaymentMethod | undefined => {
    return groups.flatMap(group => group.paymentMethods).find(paymentMethod => paymentMethod.method === method);
  };

  const currentFiled = computed(() => {
    const method = findPaymentMethodInGroups(withdrawMethods.value, PayMethod.WITHDRAW_WALLET_DIRECT_METHOD);
    const select = method?.fields.find((field: IPaymentField) => field.key === 'crypto_network');
    return select || {};
  });

  const networkSelectOptions = computed<SelectOption[]>(() => {
    const select = currentFiled.value;

    if (!select?.options) {
      return [
        {
          code: 'TON',
          maxAmount: 1000000,
          minAmount: 1,
          value: 'Ton',
          regex: ['/^(EQ|UQ)[A-Za-z0-9_-]{46}$/'],
        },
      ];
    }

    const sortedOptions = sortNetworkList(select?.options || []);

    return sortedOptions.map(option => ({
      value: option.name,
      minAmount: option.minAmount,
      maxAmount: option.maxAmount,
      code: option.id || option.name,
      regex: option.regex,
    }));
  });

  const selectedNetworkData = computed(() => {
    return networkSelectOptions.value.find(option => option.code.toLowerCase() === 'ton');
  });

  const bonusBalance = computed(() => {
    return supportedTransactionAccounts.value?.find(item => item.id === currentCurrencyId.value);
  });

  const activeAccount = computed(() => {
    return supportedTransactionAccounts.value?.find(account => account.currency === selectedCurrency.value);
  });

  const activeAccountWithdrawalFormat = computed(() =>
    formatBalance(activeAccount.value?.currency, activeAccount.value?.withdrawalBalance)
  );

  const formatAmountMax = computed(() => {
    return formatBalance(activeAccount.value?.currency, selectedNetworkData.value?.maxAmount);
  });

  const formatAmountMin = computed(() => {
    return formatBalance(activeAccount.value?.currency, selectedNetworkData.value?.minAmount);
  });

  const withdrawalAmount = computed(() => {
    return formatBalance(activeAccount.value?.currency, amountValue.value);
  });

  const estimatedFee = computed(() => {
    const fee = selectedCurrency.value === 'TON' ? 0.0066 : 0.4325;
    return formatBalance(activeAccount.value?.currency, fee);
  });

  const youWillGet = computed(() => {
    const sum = amountValue.value - estimatedFee.value.amount;
    return formatBalance(activeAccount.value?.currency, sum);
  });

  const buttonDisabled = computed(() => {
    return (
      v$.value.$invalid ||
      Number(amountValue.value) > activeAccountWithdrawalFormat.value.amount ||
      Number(amountValue.value) < formatAmountMin.value.amount ||
      Number(amountValue.value) > formatAmountMax.value.amount ||
      isSending.value
    );
  });

  const amountValue = ref(formatAmountMin.value.amount);

  const fieldHint = computed(() => {
    const minContent = getContent(popupsData.value, defaultLocalePopupsData.value, 'wallet.withdraw.minSum') || '';
    const maxContent = getContent(popupsData.value, defaultLocalePopupsData.value, 'wallet.withdraw.maxSum') || '';
    const minAmountContent = `${minContent} ${formatAmountMin.value.amount} ${formatAmountMin.value.currency}`;
    const maxAmountContent = `${maxContent} ${formatAmountMax.value.amount} ${formatAmountMax.value.currency}`;

    return {
      message: `${minAmountContent}, ${maxAmountContent}`,
    };
  });

  const withdrawFormData = reactive({
    cryptoAddress: '',
  });

  const withdrawFormRules = computed(() => {
    const withdrawRules = {
      cryptoAddress: [{ rule: 'required' }, { rule: 'regex', arguments: selectedNetworkData.value?.regex }],
    };

    return getFormRules(withdrawRules);
  });

  const { serverFormErrors, v$, setError } = useFormValidation(withdrawFormRules, withdrawFormData);

  const toggleBottomSheetBalanceSelector = (methodName: 'open' | 'close') => {
    balanceSelector.value?.[methodName]?.();
    searchCurrency.value = '';
  };

  const onSelectCurrency = () => {
    if (isMobile.value) {
      toggleBottomSheetBalanceSelector('open');
    } else {
      isOpenBalanceSelector.value = !isOpenBalanceSelector.value;
    }
  };

  const selectCurrency = async (accountId: string) => {
    toggleBottomSheetBalanceSelector('close');
    isOpenBalanceSelector.value = false;
    const currency = supportedTransactionAccounts.value.find(account => account.id === accountId)?.currency;
    await transactionStore.getWithdrawMethods(currency);
  };

  const getWithdraw = async (): Promise<void> => {
    if (buttonDisabled.value) return;

    const params = {
      method: PayMethod.WITHDRAW_WALLET_DIRECT_METHOD,
      currency: activeAccount.value?.currency || '',
      amount: Number(withdrawalAmount.value.amount),
      accountId: activeAccount.value?.id || '',
      fields: {
        crypto_network: selectedCurrency.value === 'TON' ? null : 'TON',
        wallet_id: withdrawFormData.cryptoAddress,
      },
    };

    const { withdrawAccount } = useCoreWalletApi();

    try {
      await withdrawAccount(params);
      showAlert(
        alertsData.value?.wallet?.withdrawalProcessed || defaultLocaleAlertsData.value?.wallet?.withdrawalProcessed
      );
      closeModalSync(ModalName.WALLET_TELEGRAM_APP);
      await navigateTo('/');
    } catch (err: any) {
      if (err.response?.status === 422) {
        serverFormErrors.value = err.data?.error?.fields;
      } else {
        showAlert(alertsData.value?.global?.somethingWrong || defaultLocaleAlertsData.value?.global?.somethingWrong);
      }

      isSending.value = false;
    }
  };

  watch(formatAmountMin, formatAmountMin => {
    amountValue.value = formatAmountMin.amount;
  });

  onMounted(() => {
    transactionStore.getWithdrawMethods();
  });
</script>

<style src="~/assets/styles/components/modal-content/wallet-telegram-app-withdraw.scss" lang="scss" />
