<template>
  <button
    type="button"
    class="button-telegram-connection"
  >
    <atomic-image
      class="img"
      src="/img/ton-logo.svg"
    />

    <div class="button-telegram-connection__text">
      {{ getContent(popupsData, defaultLocalePopupsData, 'wallet.tonConnect.btnTelegram') }}
    </div>
  </button>
</template>

<script lang="ts" setup>
const { getContent } = useProjectMethods();
const { popupsData,  defaultLocalePopupsData } = storeToRefs(useGlobalStore());
</script>

<style src="~/assets/styles/components/button/telegram-connection.scss" />
