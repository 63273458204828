<template>
  <div class="deposit-ton-connected">
    <div class="deposit-ton-connected__left">
      <div class="deposit-ton-connected__img">
        <atomic-image class="img" src="/img/check.svg" />
      </div>

      <div class="deposit-ton-connected__text">
        {{ getContent(popupsData, defaultLocalePopupsData, 'wallet.tonConnect.connected') }}
      </div>

      <div class="deposit-ton-connected__text deposit-ton-connected__text--truncate">{{ tonAddress }}</div>
    </div>

    <div class="deposit-ton-connected__btn" @click="onDisconnect">
      {{ getContent(popupsData, defaultLocalePopupsData, 'wallet.tonConnect.disconnect') }}
    </div>
  </div>
</template>

<script setup lang="ts">
  defineProps<{
    tonAddress: string;
  }>();

  const emits = defineEmits<{
    (event: 'onDisconnect'): void;
  }>();

  const { getContent } = useProjectMethods();
  const { popupsData, defaultLocalePopupsData } = storeToRefs(useGlobalStore());

  const onDisconnect = () => {
    emits('onDisconnect');
  };
</script>

<style lang="scss" src="~/assets/styles/components/form/deposit-ton-connected.scss" />
