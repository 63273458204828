<template>
  <div class="slider-amount-step" ref="sliderRef">
    <div class="slider-amount-step__track">
      <div class="slider-amount-step__track--active" :style="{ width: `${thumbPosition}%` }"></div>
    </div>

    <div
      class="slider-amount-step__step"
      v-for="(step, index) in steps"
      :key="index"
      :style="{ left: `${step * 100}%` }"
    />

    <div
      class="slider-amount-step__thumb"
      :style="{ left: `${thumbPosition}%` }"
      @mousedown="startDrag"
      @touchstart="startDrag"
    >
      <div class="slider-amount-step__thumb-inner"></div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref, watch, onMounted, onUnmounted } from 'vue';

  const emit = defineEmits<{
    (e: 'update', value: number): void;
  }>();

  const props = defineProps<{
    initialPercentage: number;
  }>();

  const { taptic } = useHaptics();

  const steps = ref([0, 0.25, 0.5, 0.75, 1]);
  const currentValue = ref(0);
  const sliderRef = ref<HTMLElement | null>(null);

  let isDragging = false;

  const thumbSize = 20;

  const thumbPosition = computed(() => {
    if (!sliderRef.value) return 0;

    const sliderWidth = sliderRef.value.offsetWidth;
    const offsetCorrection = 1;
    const offsetPercentage = ((thumbSize / 2 - offsetCorrection) / sliderWidth) * 100;

    return Math.min(Math.max(currentValue.value * 100, offsetPercentage), 100 - offsetPercentage);
  });

  const setInitialValue = (percentage: number) => {
    const relativeValue = Math.min(Math.max(percentage / 100, 0), 1);
    currentValue.value = relativeValue;
  };

  const startDrag = (event: MouseEvent | TouchEvent) => {
    event.preventDefault();
    isDragging = true;
  };

  const onMove = (event: MouseEvent | TouchEvent) => {
    if (!isDragging || !sliderRef.value) return;

    const moveX = event instanceof MouseEvent ? event.clientX : event.touches[0].clientX;
    const sliderRect = sliderRef.value.getBoundingClientRect();
    const relativePosition = Math.min(Math.max((moveX - sliderRect.left) / sliderRect.width, 0), 1); // Позиция от 0 до 1
    const newValue = relativePosition;

    currentValue.value = getClosestStep(newValue);

    emit('update', Math.round(currentValue.value * 100));
  };

  const endDrag = () => {
    isDragging = false;
  };

  const getClosestStep = (value: number) => {
    return steps.value.reduce((prev, curr) => (Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev));
  };

  watch(currentValue, (newValue, oldValue) => {
    if (newValue !== oldValue) {
      taptic('soft');
    }
  });

  watch(
    () => props.initialPercentage,
    newPercentage => {
      setInitialValue(newPercentage);
    }
  );

  onMounted(() => {
    document.addEventListener('mousemove', onMove);
    document.addEventListener('mouseup', endDrag);
    document.addEventListener('touchmove', onMove);
    document.addEventListener('touchend', endDrag);

    setInitialValue(props.initialPercentage);
  });

  onUnmounted(() => {
    document.removeEventListener('mousemove', onMove);
    document.removeEventListener('mouseup', endDrag);
    document.removeEventListener('touchmove', onMove);
    document.removeEventListener('touchend', endDrag);
  });
</script>

<style src="~/assets/styles/components/slider/amount-step.scss" lang="scss" />
