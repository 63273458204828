<template>
  <div class="wallet-telegram-app-deposit">
    <div v-if="isWalletConnected" class="wallet-telegram-app-deposit__connected">
      <form-deposit-ton-connected :tonAddress="walletAddress" @onDisconnect="disconnect" />

      <skeletor v-if="isLoadingSupportedCurrencies" class="wallet-telegram-app-deposit__skeletor" as="div" />

      <form-input-display
        v-else
        :label="getContent(fieldsSettings, defaultLocaleFieldsSettings, 'fieldsControls.balance.label')"
        :value="balanceObject?.value || ''"
        :img-url="balanceObject?.mask"
        :is-mobile="isMobile"
        :is-open="isOpenBalanceSelector"
        @click-selector="onSelectCurrency"
      >
        <template v-if="!isMobile" #header>
          <div class="header">
            <form-input-search
              v-model:value="searchCurrency"
              :placeholder="getContent(fieldsSettings, defaultLocaleFieldsSettings, `fieldsControls.search.label`)"
            />
          </div>
        </template>

        <list-simple
          v-model:value="currentCurrencyId"
          :searchValue="searchCurrency"
          :list="filteredCurrency"
          @selected="selectCurrency"
        />
      </form-input-display>

      <skeletor
        v-if="isLoadingSupportedCurrencies || isLoadingDepositMethodsForCurrentCurrency"
        class="wallet-telegram-app-deposit__skeletor--ton-pay"
        as="div"
      />

      <template v-else>
        <wallet-limit
          v-model:limit-error="limitError"
          :selected-currency="selectedCurrency"
          :deposit-balance="amountValue"
          v-model:deposit-error="depositError"
        />

        <template v-if="!depositError">
          <div class="wallet-telegram-app-deposit__wrap">
            <form-input-exchange
              v-model:value="amountValue"
              :valueRight="formatRightAmount"
              :label="getContent(popupsData, defaultLocalePopupsData, 'wallet.tonConnect.amount')"
              :labelRight="labelRight"
              :hint="fieldHint"
              :placeholder="getContent(popupsData, defaultLocalePopupsData, 'wallet.tonConnect.placeholderDeposit')"
              :min="formatAmountMin.amount"
              :max="formatAmountMax.amount"
              :default-value="formatAmountMin.amount"
              :currency="selectedCurrency"
              name="depositSum"
              @input="onInputAmount"
              @toggleRevert="toggleRevert"
            />

            <slider-amount-step :initialPercentage="initialPercentage" @update="onSelectPercent" />

            <form-deposit-preset-amount-buttons :max="maxPresetAmount" @onSelectPreset="onSelectPreset" />
          </div>

          <button-crypto-pay :is-loading="isProcessing" :disabled-pay-btn="disabledPayBtn" @on-lick-pay="pay" />
        </template>
      </template>
    </div>

    <template v-else>
      <skeletor v-if="isLoadingSupportedCurrencies" class="wallet-telegram-app-deposit__skeletor" as="div" />

      <form-input-display
        v-else
        :label="getContent(fieldsSettings, defaultLocaleFieldsSettings, 'fieldsControls.balance.label')"
        :value="balanceObject?.value || ''"
        :img-url="balanceObject?.mask"
        :is-mobile="isMobile"
        :is-open="isOpenBalanceSelector"
        @click-selector="onSelectCurrency"
      >
        <template v-if="!isMobile" #header>
          <div class="header">
            <form-input-search
              v-model:value="searchCurrency"
              :placeholder="getContent(fieldsSettings, defaultLocaleFieldsSettings, `fieldsControls.search.label`)"
            />
          </div>
        </template>

        <list-simple
          v-model:value="currentCurrencyId"
          :searchValue="searchCurrency"
          :list="filteredCurrency"
          @selected="selectCurrency"
        />
      </form-input-display>

      <atomic-empty-wallet
        :title="getContent(popupsData, defaultLocalePopupsData, 'wallet.tonConnect.emptyTitle')"
        :subtitle="getContent(popupsData, defaultLocalePopupsData, 'wallet.tonConnect.emptySubtitle')"
      />

      <button-telegram-connection @click="openModal" />
    </template>

    <bonus-deposit-code v-if="!depositError" />

    <layout-bottom-sheet ref="balanceSelector">
      <template #header>
        <span class="title">
          {{ getContent(layoutData, defaultLocaleLayoutData, 'header.balance.tabs.coins') }}
        </span>
      </template>

      <list-simple
        v-model:value="currentCurrencyId"
        :searchValue="searchCurrency"
        :list="filteredCurrency"
        @selected="selectCurrency"
      />

      <template #footer>
        <button-base type="gray" size="sm" @click="toggleBottomSheetBalanceSelector('close')">
          {{ getContent(layoutData, defaultLocaleLayoutData, 'buttons.close') }}
        </button-base>
      </template>
    </layout-bottom-sheet>
  </div>
</template>

<script setup lang="ts">
  import { Skeletor } from 'vue-skeletor';

  import { PayMethod } from '@skeleton/consts/method';

  import type { IPaymentField } from '@skeleton/core/types';

  interface ICurrentDepositMethod {
    amountMin?: number;
    amountMax?: number;
    method?: string;
    fields: IPaymentField[];
  }

  const emits = defineEmits(['closeWallet']);

  const {
    openModal,
    isWalletConnected,
    isProcessing,
    disconnect,
    walletAddress,
    tonCoinBalance,
    usdtBalance,
    modalState,
    sendTonCoins,
    sendUSDTjettons,
    restored,
    truncateToDecimals,
  } = useTonConnect();

  const deviceStore = useDeviceStore();
  const { isMobile } = storeToRefs(deviceStore);
  const { getContent } = useProjectMethods();
  const { depositAccount } = useCoreWalletApi();
  const { showAlert } = useLayoutStore();
  const {
    popupsData,
    defaultLocalePopupsData,
    alertsData,
    defaultLocaleAlertsData,
    fieldsSettings,
    defaultLocaleFieldsSettings,
    layoutData,
    defaultLocaleLayoutData,
  } = storeToRefs(useGlobalStore());
  const {
    currencyList,
    balanceObject,
    currentCurrencyId,
    selectedCurrency,
    supportedTransactionAccounts,
    getDepositMethodsForCurrentCurrency,
    isLoadingSupportedCurrencies,
    isLoadingDepositMethodsForCurrentCurrency,
  } = useTransactionOperations({ method: PayMethod.DEPOSIT_WALLET_DIRECT_METHOD });

  const { convertFromUSD, convertCurrency, fetchRates } = useRates();
  const { limitsContent, defaultLimitsContent } = storeToRefs(useLimitsStore());

  const isOpenBalanceSelector = ref(false);
  const limitError = ref(false);
  const depositError = ref(false);

  const filteredCurrency = computed(() => {
    return currencyList.value.filter(item => item.name === 'USDT' || item.name === 'TON');
  });

  const coinValue = computed(() => {
    switch (selectedCurrency.value) {
      case 'USDT':
        return usdtBalance.value;
      case 'TON':
        return tonCoinBalance.value;
      default:
        return '0.00';
    }
  });

  const labelRight = computed(() => {
    return `${getContent(popupsData.value, defaultLocalePopupsData.value, 'wallet.tonConnect.wallet')}: ${coinValue.value} ${selectedCurrency.value}`;
  });

  const searchCurrency = ref('');
  const balanceSelector = ref();
  const amountValue = ref('');
  const valueRight = ref(0);
  const isRevert = ref(false);

  const currentDepositMethod = reactive<ICurrentDepositMethod>({
    amountMin: 0,
    amountMax: 0,
    method: PayMethod.DEPOSIT_WALLET_DIRECT_METHOD,
    fields: [],
  });

  const minAmount = ref(0);
  const maxAmount = ref(0);
  const toAddress = ref<string>();
  const destinationTag = ref<string>();

  const initialPercentage = computed(() => {
    if (Number(coinValue.value) <= 0) return 0;

    const value = isRevert.value
      ? convertCurrency(amountValue.value, 'USD', selectedCurrency.value)
      : amountValue.value;
    const percentage = ((Number(value) - minAmount.value) / (Number(coinValue.value) - minAmount.value)) * 100;

    return Math.min(Math.max(percentage, 0), 100);
  });

  const maxPresetAmount = computed(() => {
    return convertCurrency(Number(coinValue.value), selectedCurrency.value);
  });

  const formatRightAmount = computed(() => truncateToDecimals(String(valueRight.value), 2));

  const formatAmountMax = computed(() => ({ amount: maxAmount.value, currency: selectedCurrency.value }));
  const formatAmountMin = computed(() => ({ amount: minAmount.value, currency: selectedCurrency.value }));

  const fieldHint = computed(() => {
    if (limitError.value) {
      return {
        message: getContent(limitsContent.value, defaultLimitsContent.value, 'deposit.wallet.title'),
        variant: 'error',
      };
    }

    return {
      message: `${
        getContent(popupsData.value, defaultLocalePopupsData.value, 'wallet.deposit.minSum') || ''
      } ${formatAmountMin.value.amount} ${formatAmountMin.value.currency}`,
      variant: '',
    };
  });

  const getAccountId = (): string => {
    const findAccount = supportedTransactionAccounts.value.find(account => account.id === currentCurrencyId.value);
    return findAccount?.id || '';
  };

  const disabledPayBtn = computed(() => {
    const value = isRevert.value ? valueRight.value : amountValue.value;
    const currentBalance = selectedCurrency.value === 'USDT' ? usdtBalance.value : tonCoinBalance.value;
    return !Number(value) || isProcessing.value || Number(value) > Number(currentBalance) || limitError.value;
  });

  const changeRevert = (value: boolean): void => {
    isRevert.value = value;
  };

  const onInputAmount = (value: number | string): void => {
    if (isRevert.value) {
      valueRight.value = convertCurrency(value, 'USD', selectedCurrency.value);
    } else {
      valueRight.value = convertCurrency(value, selectedCurrency.value);
    }
  };

  const toggleRevert = (value: boolean): void => {
    changeRevert(value);
    const copyAmount = Number(amountValue.value);
    amountValue.value = formatRightAmount.value;
    valueRight.value = copyAmount;
  };

  const onSelectPreset = (value: number): void => {
    const amount = isRevert.value ? value : convertFromUSD(value, selectedCurrency.value);
    amountValue.value = String(amount);
    onInputAmount(amount);
  };

  const onSelectPercent = (percent: number): void => {
    if (!Number(coinValue.value)) return;

    const value = (percent / 100) * (Number(coinValue.value) - minAmount.value) + minAmount.value;
    const truncateValue = truncateToDecimals(String(value), 2);
    const amount = isRevert.value ? convertCurrency(truncateValue, selectedCurrency.value) : truncateValue;
    amountValue.value = String(amount);
    onInputAmount(amountValue.value);
  };

  const getDepositInvoice = async () => {
    if (limitError.value) return;

    let depositData;
    try {
      depositData = {
        method: '0x.deposit.wallet_direct',
        currency: selectedCurrency.value || '',
        amount: currentDepositMethod.amountMin || 5,
        accountId: getAccountId(),
        redirectSuccessUrl: window.location.origin,
        redirectErrorUrl: window.location.origin,
        fields: { crypto_network: selectedCurrency.value === 'TON' ? null : 'TON' },
        isBonusDecline: false,
      };

      const depositResponse = await depositAccount(depositData);

      toAddress.value = depositResponse?.address;
      destinationTag.value = depositResponse?.destinationTag;
    } catch (e) {
      console.error(e);
    }
  };

  const pay = async () => {
    let result = false;

    const value = isRevert.value ? valueRight.value : amountValue.value;

    if (selectedCurrency.value === 'USDT') {
      result = await sendUSDTjettons(String(value), toAddress.value || '', destinationTag.value || '');
    }

    if (selectedCurrency.value === 'TON') {
      result = await sendTonCoins(String(value), toAddress.value || '', destinationTag.value || '');
    }

    if (result) {
      emits('closeWallet');
      showAlert(
        alertsData.value?.wallet?.transactionIsPending || defaultLocaleAlertsData.value?.wallet?.transactionIsPending
      );
    } else {
      showAlert(alertsData.value?.global?.somethingWrong || defaultLocaleAlertsData.value?.global?.somethingWrong);
    }
  };

  const setMethodForCurrency = async () => {
    const group = await getDepositMethodsForCurrentCurrency();

    const allMethods = group.flatMap(group => group.paymentMethods);
    const method = allMethods.find(item => item.method === PayMethod.DEPOSIT_WALLET_DIRECT_METHOD);

    if (method) {
      minAmount.value = method.amountMin;
      maxAmount.value = method.amountMax;
      currentDepositMethod.amountMin = method.amountMin;
      currentDepositMethod.amountMax = method.amountMax;
      currentDepositMethod.method = method.method;
      currentDepositMethod.fields = method.fields;
    }
  };

  const selectCurrency = async (accountId: string) => {
    toggleBottomSheetBalanceSelector('close');
    isOpenBalanceSelector.value = false;
    currentCurrencyId.value = accountId;
    changeRevert(false);
    amountValue.value = '';
    onInputAmount(Number(amountValue.value));
  };

  const toggleBottomSheetBalanceSelector = (methodName: 'open' | 'close') => {
    balanceSelector.value?.[methodName]?.();
    searchCurrency.value = '';
  };

  const onSelectCurrency = () => {
    if (isMobile.value) {
      toggleBottomSheetBalanceSelector('open');
    } else {
      isOpenBalanceSelector.value = !isOpenBalanceSelector.value;
    }
  };

  watch(isWalletConnected, isWalletConnected => {
    if (isWalletConnected) {
      changeRevert(false);
      onInputAmount(Number(amountValue.value));
    }
  });

  watch(
    restored,
    () => {
      if (restored.value && modalState.value?.status === 'closed' && !isWalletConnected.value) {
        openModal();
      }
    },
    { immediate: true }
  );

  const { getLimits } = useLimitsStore();

  watch([currentCurrencyId], () => {
    getLimits();
    setMethodForCurrency();
    getDepositInvoice();
  });

  onMounted(() => {
    fetchRates();
  });
</script>

<style src="~/assets/styles/components/modal-content/wallet-telegram-app-deposit.scss" lang="scss" />
